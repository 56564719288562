@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
}

* {
  scrollbar-width: none;
}

a {
  color: #5ae9cf;
}

.CookieConsent .buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
